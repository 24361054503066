<template>
  <div class="animated fadeIn">
    <div class="container mb-5">
      <div class="card bg-white p-5 shadow mt-6">
        <div class="card-body">
          <div class="py-3 text-center">
            <h1 class="h3">{{ $t('message.feedback') }}</h1>
            <p class="lead">
              {{ $t('message.feedback_intro') }}
            </p>
            <p class="lead" v-if="error_message">
              <b-alert show variant="danger"> {{error_message}}</b-alert>
            </p>
          </div>
          <form @submit.prevent="submit">
            <b-row>
              <b-col sm="12">
                <validation-field
                  :label="$t('message.message')"
                  :v="$v.form.Feedback">
                    <textarea class="form-control" id="Feedback"
                              type="textarea"
                              rows="15"
                              v-model="form.Feedback"
                              v-on:blur="$v.form.Feedback.$touch()"
                    />
                </validation-field>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12">
                <label>{{$t('message.score')}}</label><br/>
                <star-rating v-model="form.Score"></star-rating>
              </b-col>
            </b-row>
            <hr class="mb-4">
            <b-row>
              <b-col sm="12" md="6">
                <b-button
                  :class="{invalid:$v.form.$invalid}"
                  block
                  style="margin-bottom: 2px !important;"
                  type="submit" class="bg-theme-secondary mb-2">
                  {{$t('message.button_send_text')}}
                </b-button>
              </b-col>
              <b-col sm="12" md="6">
                <b-button
                  block type="button"
                  v-on:click="reset()"
                  variant="secondary">
                  {{$t('message.button_reset_text')}}
                </b-button>
              </b-col>
            </b-row>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ValidationField from "../../components/ui/ValidationField";
  import ApiResolver from '../../components/common/ApiResolver';
  import Api from '../../components/common/Api';
  import {required} from 'vuelidate/lib/validators';
  import StarRating from 'vue-star-rating';

  export default {
    name: "Feedback",
    components: {
      ValidationField,
      StarRating
    },
    data() {
      return {
        form: {
          Feedback: null,
          Score: 0,
          Referral: 'admin',
          Referral_Url: '/feedback'
        },
        error_message: null
      }
    },
    computed: {},
    methods: {
      submit() {
        this.form.Score = this.form.Score.toString();
        this.$v.form.$touch();
        if (this.$v.form.$invalid) return;
        Api
          .put(ApiResolver.get('FEEDBACK'), JSON.stringify(this.form))
          .then(response => {
            this.$store.dispatch('alerts/setAlert', {
              'type': 'success',
              'text': this.$t('message.feedback_sent')
            });
            this.$router.push({name:'categories'})
          })
          .catch(reason => {
            this.$store.dispatch('alerts/setAlert', {
              type: 'danger',
              text: reason.title + ' : ' + reason.detail
            });
          });
      },
      reset() {
        this.form = {
          Feedback: null,
          Score: 0
        };
        this.$v.form.reset();
      }
    },
    mounted() {
    },
    validations: {
      form: {
        Feedback: {
          required: required,
        }
      }
    },
  }
</script>

<style type="text/css">
  .vue-star-rating-rating-text {
    display: none
  }
</style>
<style scoped>
  .hasError label {
    color: red;
  }
  .error {
    color: red
  }
</style>
